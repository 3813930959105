<template>
    <BT-Blade-Items
        bladeName="ordering-user-activities"
        :headers="[
            { text: 'Item', value: 'itemID', title: 1 },
            { text: 'Notes', value: 'notes', display: true, subtitle: 1 }]"
        hideActions
        navigation="ordering-user-activities"
        :searchProps="['itemID']"
        showList
        title="Order Activity Search Module"
        useServerPagination>
        <template v-slot:listItem="{ item }">
            <v-list-item-content>
                <v-list-item-title>{{ item.itemID }} {{ item.notes }} by <span class="mx-2"><BT-Entity alternateText="system" inline navigation="users" itemText="userName" :itemValue="item.userID" /></span> on <span class="ml-2">{{ item.createdOn | toLongDateAndTime }}</span></v-list-item-title>
            </v-list-item-content>
        </template>
    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Ordering-User-Activities-Blade',
    data() {
        return {

        }
    }
}
</script>